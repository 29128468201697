import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ASSET_BASE_URL } from "/config/constants";
import LanguageSelector from "../molecules/LanguageSelector";

import styles from "./Footer.module.scss";

export default function Footer({}) {
  const router = useRouter();
  const { locale, query } = router;
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  useEffect(() => {
    const js = window.document.createElement("script");
    js.src = "https://www.shopperapproved.com/seals/certificate.js";
    js.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(js);
  }, []);

  const links = [
    {
      label: t("tar_static.footer.about_us"),
      url: "/about",
    },
    {
      label: t("signup02.extra_content.footer.contact_us"),
      url: "/contact",
    },
    {
      label: t("signup02.extra_content.footer.terms_of_use"),
      url: "/tos",
    },
    {
      label: t("signup02.extra_content.footer.privacy_policy"),
      url: "/privacy",
    },
    {
      label: t("signup02.extra_content.footer.agents_join_here"),
      url: "https://www.referralexchange.com/referral?site=917",
    },
    {
      label: "Do Not Sell My Information",
      url: "http://www.referralexchange.com/information",
    },
  ];

  return (
    <footer className={styles["Footer"]}>
      <div className={styles["Footer__container"]}>
        <div className={styles["Footer__top"]}>
          <ul className={styles["Footer__nav"]}>
            {links.map((link, index) => {
              return (
                <li key={index}>
                  <a href={link.url}>{link.label}</a>
                </li>
              );
            })}
          </ul>
          <div className={styles["Footer__icons"]}>
            <div className={styles["Footer__icon"]}>
              <a href="https://www.shopperapproved.com/reviews/RealEstateAgents.com/">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="https://www.shopperapproved.com/seals/34366-r.gif"
                  alt="Customer Reviews"
                  width={90}
                  height={35}
                />
              </a>
            </div>
            <div className={styles["Footer__icon"]}>
              <Image
                src={`${ASSET_BASE_URL}/verisign.png`}
                alt={"Verisign"}
                width={63}
                height={37}
              />
            </div>
            <div className={styles["Footer__icon"]}>
              <Image
                src={`${ASSET_BASE_URL}/realtor.png`}
                alt={"Realtor"}
                width={34}
                height={38}
              />
            </div>
            <div
              className={`${styles["Footer__icon"]} ${styles["bbbContainer"]}`}
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={`https://seal-goldengate.bbb.org/logo/ruhzbum/referralexchange-458879.png`}
                layout="fill"
                objectfit="cover"
                alt={"Realtor"}
                width={89}
                height={34}
              />
            </div>
          </div>
        </div>
        <div className={styles["Footer__bottom"]}>
          <div className={styles["Footer__copyright"]}>
            {t(
              `signup02.extra_content.footer.${
                query.brand === "rex" ? "rex_disclaimer" : "disclaimer"
              }`,
              {
                currentYear: new Date().getFullYear(),
              }
            )}
          </div>
          <LanguageSelector />
        </div>
      </div>
    </footer>
  );
}
