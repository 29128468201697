import { StyleSheet, css } from "aphrodite";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ASSET_BASE_URL } from "/config/constants";
import { useExperiment } from "../../../../contexts/ExperimentContext";
import styles from "./Header.module.scss";

export default function BorderlessHeader({ experience, country }) {
  const router = useRouter();

  const [canadaFlag, setCanadaFlag] = useState(false);
  const [whichBrand, setWhichBrand] = useState(router.query?.brand);
  const { t } = useTranslation(["translation", "common"]);
  const { experiment } = useExperiment();

  useEffect(() => {
    const utm_country = router.query?.utm_country?.toLocaleLowerCase();
    const oldCountry = window.localStorage.getItem("country");

    let localCountry = oldCountry;

    if (utm_country) {
      localCountry = utm_country;
      window.localStorage.setItem("country", utm_country);
    } else if (!oldCountry) {
      localCountry = country;
      window.localStorage.setItem("country", country);
    }

    const someDate = new Date();
    const numberOfDaysToAdd = 4;
    const result = someDate.setDate(
      someDate.getDate() + numberOfDaysToAdd.toString()
    );

    if (oldCountry !== country) {
      window.localStorage.setItem("countryExpiry", result);
    }

    const countryExpiry = window.localStorage.getItem("countryExpiry");
    let isCountryExpired = false;

    if (countryExpiry) {
      isCountryExpired = new Date() > new Date(countryExpiry);
    }
    setCanadaFlag(
      (localCountry?.toLocaleLowerCase() === "ca" ||
        localCountry?.toLocaleLowerCase() === "canada") &&
        !isCountryExpired
    );
  }, [setCanadaFlag, router.query.utm_country, country]);

  useEffect(() => {
    setWhichBrand(router.query.brand);
  }, [router.query.brand]);

  const PHONE_NUMBERS = {
    usa: {
      tel: "+18885862349",
      display: "+1 (888) 586-2349",
    },
    us: {
      tel: "+18885862349",
      display: "+1 (888) 586-2349",
    },
    ca: {
      tel: "+18888851805",
      display: "+1 (888) 885-1805",
    },
    canada: {
      tel: "+18888851805",
      display: "+1 (888) 885-1805",
    },
    rea: {
      tel: "+18884910999",
      display: "+1 (888) 491-0999",
    },
  };

  const ALT_PHONE_NUMBERS = {
    rex: {
      tel: "+1415­693­1400",
      display: "+1 (415­) 693­-1400",
    },
  };

  return (
    <>
      <header className={styles["Header"]}>
        <div
          className={[
            styles["Header__container"],
            styles["Header__container_borderless"],
          ].join(" ")}
        >
          <div className={styles["Header__brand"]}>
            <Link href="/">
              <div className={styles["Header__borderless_logo"]}>
                <Image
                  src={`${ASSET_BASE_URL}/rea-logo-with-icon-black.png`}
                  alt={"RealEstateAgents.com"}
                  quality={100}
                  sizes="(min-width: 768px) 234px, 210px"
                  priority
                  fill
                />
              </div>
            </Link>
            <span className={styles["Header__flag"]}>
              <Image
                alt={`${canadaFlag ? "Canada Flag" : "USA Flag"}`}
                src={`${
                  canadaFlag
                    ? `${ASSET_BASE_URL}/canada-flag.jpeg`
                    : `${ASSET_BASE_URL}/static/svgs/usa-flag.svg`
                }`}
                height={canadaFlag ? 20 : 30}
                width={canadaFlag ? 37.64 : 40}
              />
            </span>
            <div
              className={[
                styles["Header__phone"],
                styles["Header__phone_borderless"],
              ].join(" ")}
            >
              <a
                className={styles["Header__phone_link"]}
                href={`tel:${
                  whichBrand === "rex"
                    ? ALT_PHONE_NUMBERS[whichBrand].tel
                    : PHONE_NUMBERS[country.toLocaleLowerCase()].tel
                }`}
              >
                <span>
                  <i
                    className={`fa-regular fa-phone-arrow-up-right ${css(
                      aphStyles.icon
                    )}`}
                  />
                  <span className={css(aphStyles.desktop)}>
                    {t("navbar.need_help")}
                  </span>
                </span>

                <span
                  className={css(aphStyles.desktop)}
                  style={{ fontWeight: 700 }}
                >
                  {whichBrand === "rex"
                    ? ALT_PHONE_NUMBERS[whichBrand].display
                    : PHONE_NUMBERS[country.toLocaleLowerCase()].display}
                </span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

const aphStyles = StyleSheet.create({
  desktop: {
    "@media only screen and (max-width: 767px)": {
      display: "none",
    },
  },
  icon: {
    color: "#027850",
    marginRight: 7,
    "@media only screen and (max-width: 767px)": {
      fontSize: 16,
    },
  },
});
