import BugsnagPackage from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { useEffect } from "react";

import BorderlessLayout from "/components/layouts/Borderless";
import Layout from "/components/layouts/Default";

// Styles
import "/styles/globals.scss";
import "../components/steps/contact/styles/react-tel-input.css";
import "../components/steps/contact/styles/contact.css";

// Config
import "../i18";

// Context
import { ExperimentContextProvider } from "/contexts/ExperimentContext";
import { useExperiment } from "../contexts/ExperimentContext";
import "dialog-polyfill/dialog-polyfill.css";

const BugsnagInit = ({ releaseStage }) => {
  return BugsnagPackage.start({
    apiKey: "1f5f51410360c6de59c1bd7ed38c362e",
    appVersion: process.env.NEXT_PUBLIC_BUILD_ID
      ? `${process.env.NEXT_PUBLIC_BUILD_ID}-${process.env.NEXT_PUBLIC_ENV}`
      : `dev-${process.env.NEXT_PUBLIC_ENV}`,
    plugins: [new BugsnagPluginReact(React)],
    enabledReleaseStages: ["production", "staging"],
    releaseStage: releaseStage && releaseStage,
  });
};

const Bugsnag = BugsnagInit({
  releaseStage: process.env.NEXT_PUBLIC_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin("react");

const AppContent = ({ Component, pageProps }) => {
  const { experiment, loaded } = useExperiment();
  const router = useRouter();

  useEffect(() => {
    // Run only on the client
    if (
      typeof window !== "undefined" &&
      typeof HTMLDialogElement === "undefined"
    ) {
      (async () => {
        const dialogPolyfill = await import("dialog-polyfill");
        const dialogs = document.querySelectorAll("dialog");
        dialogs.forEach((dialog) => {
          dialogPolyfill.default.registerDialog(dialog);
        });
      })();
    }
  }, []);

  useEffect(() => {
    if (typeof queueMicrotask !== "function") {
      window.queueMicrotask = function (callback) {
        Promise.resolve()
          .then(callback)
          .catch((err) =>
            setTimeout(() => {
              throw err;
            }, 0)
          );
      };
    }
  }, []);

  // Conditionally select the layout
  const getLayout =
    Component.getLayout ||
    ((page) => {
      if (!loaded) {
        return null; // Fallback layout while loading
      }
      if (experiment.includes("borderless") && router.pathname !== "/") {
        return (
          <BorderlessLayout country={pageProps?.locationData?.country || "usa"}>
            {page}
          </BorderlessLayout>
        );
      }
      return (
        <Layout country={pageProps?.locationData?.country || "usa"}>
          {page}
        </Layout>
      );
    });

  return getLayout(<Component {...pageProps} />);
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const canonicalUrl = (
    `https://www.realestateagents.com/compare-agents` +
    (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  return (
    <ErrorBoundary>
      <Head>
        <title>Top REALTORS, Top Real Estate Agents</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <Script
        src="https://kit.fontawesome.com/498b7bbafd.js"
        crossOrigin="anonymous"
        async={true}
      />
      <ExperimentContextProvider>
        <AppContent Component={Component} pageProps={pageProps} />
      </ExperimentContextProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
