import { StyleSheet, css } from "aphrodite";
import BorderlessHeader from "/components/experiments/borderless/organisms/BorderlessHeader";
import Footer from "/components/experiments/borderless/organisms/Footer";

export default function BorderlessLayout({ children, country }) {
  return (
    <div className={`borderless ${css(styles.borderless)}`} id="app">
      <div className={css(styles.top)}>
        <BorderlessHeader country={country} />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
}

const styles = StyleSheet.create({
  top: {
    minHeight: "100vh",
  },
  borderless: {
    background: "#FFFDFA",
  },
});
